import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { Select2OptionData } from 'ng-Select2';
import { formatData } from './format-data'; // Importez la fonction de formatage ici
import { Options } from 'select2';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/services/storage.service';
import { CommonService } from 'src/app/services/common.service';
declare var $;

@Component({
  selector: "app-step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["./step2.component.scss"],
})
export class Step2Component implements OnInit, OnChanges {
  public exampleData: Array<Select2OptionData>;
  public options: Options;
  public optionsPicto: Options;
  public value: string[];
  formGroupe: FormGroup = new FormGroup({
    prixPromotion: new FormControl(),
    prixNormal: new FormControl(),
    montant15: new FormControl(),
    tailles: new FormControl(),
    pictoIcon: new FormControl("Choisir un picto", Validators.required),
    pictoName: new FormControl("", [Validators.maxLength(30)]),
  });
  listImages = [];
  produit: any = {};
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "valeur",
    selectAllText: "Tout",
    unSelectAllText: "Tout",
    // itemsShowLimit: 3,
    allowSearchFilter: false,
  };
  showSpinner = false;
  showError = false;
  listCouleurDispo: any = [{ id: 0, listImages: [] }];
  @Input() listCaracteristique = [];
  @Input() listCouleur = [];
  @Input() listTailles = [];
  @Input() formData;
  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  productEdit;
  user;
  vendeur;
  pictos = []; //ws picto list
  pictoList = []; //list picto selected
  selectedPicto: any;
  retourService: any;
  taillesPerItem = [];
  initialColors = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.optionsPicto = {
      multiple: true,
      tags: false,
      closeOnSelect: false,
      templateResult: formatData,
      templateSelection: formatData,
    };
    this.httpClient.get(environment.ws_url + "pictos").subscribe((res: any) => {
      this.pictos = res.data;
    });
    this.productEdit = this.activatedRoute.snapshot.paramMap.get("idProduit");
    this.httpClient
      .get(environment.ws_url + `users/vendeur/byId`)
      .subscribe((dataMenu: any) => {
        this.vendeur = dataMenu.data;
        this.user = JSON.parse(this.storageService.getItem("User"));
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
        this.produit = this.formData[1];
        this.listCaracteristique.forEach((elt: any) => {
          this.formGroupe.addControl(
            `caracteristique${elt.cat_car_id}`,
            new FormControl("", [Validators.required])
          );
        });
        this.listCouleurDispo.forEach((elt) => {
          // this.formGroupe.addControl('couleur' + elt.id, new FormControl(''));
          // this.formGroupe.addControl('taille' + elt.id, new FormControl(''));
          // if (!this.produit.has_taille) {
          //   this.formGroupe.addControl('pieceDisponnible' + elt.id, new FormControl('', Validators.min(1)));
          //   const obj = {};
          //   obj['pieceDisponnible' + elt.id] = 1;
          //   this.formGroupe.patchValue(obj);
          // }
          if (this.produit.has_color) {
            this.formGroupe.addControl(
              "couleur" + elt.id,
              new FormControl("", [Validators.required])
            );
          } else {
            this.formGroupe.addControl("couleur" + elt.id, new FormControl(""));
          }
          if (!this.produit.has_taille) {
            this.formGroupe.addControl("taille" + elt.id, new FormControl(""));
            if (this.productEdit) {
              this.formGroupe.addControl(
                "pieceDisponnible" + elt.id,
                new FormControl("")
              );
            } else {
              this.formGroupe.addControl(
                "pieceDisponnible" + elt.id,
                new FormControl("", Validators.min(1))
              );
            }
            const obj = {};
            obj["pieceDisponnible" + elt.id] = 1;
            this.formGroupe.patchValue(obj);
          } else {
            this.formGroupe.addControl(
              "taille" + elt.id,
              new FormControl("", [Validators.required])
            );
          }
          this.formGroupe.addControl(
            "listImage" + elt.id,
            new FormControl("", Validators.min(1))
          );
          const img = {};
          img["listImage" + elt.id] = 0;
          this.formGroupe.patchValue(img);
        });
        this.value = ["multiple2", "multiple4"];
        this.options = {
          multiple: true,
          tags: false,
          closeOnSelect: false,
        };

        this.dropdownSettings = {
          singleSelection: false,
          idField: "id",
          textField: "valeur",
          // itemsShowLimit: 3,
          allowSearchFilter: false,
          enableCheckAll: false,
          noDataAvailablePlaceholderText: "Pas de donées",
        };

        if (this.productEdit) {
          this.listCouleurDispo = [];
          this.httpClient.get(environment.ws_url + `produits/${"HADEEN"}/${this.productEdit}`).subscribe((response: any) => {
            this.retourService = response;
            if (response.data.caracs) {
              const newList = [];
              const caracteristiques = response.data.caracs.map(
                (e) => e.caracteristique
              );
              this.listCaracteristique.forEach((e) => {
                caracteristiques.forEach((car) => {
                  if (
                    e.caracteristique.code === car.code &&
                    e.caracteristique.nom === car.nom &&
                    e.caracteristique.type === car.type
                  ) {
                    newList.push(e);
                  }
                });
              });
              this.listCaracteristique = newList;
            }
            this.httpClient.get(environment.ws_url + `pictos/HADEEN/${this.productEdit}`).subscribe((response: any) => {
              this.pictoList = response.data.map((item: any) => {
                return {
                  picto_id: item.id,
                  id: item.picto.id,
                  url: item.picto.url,
                  picto_text: item.picto_text,
                };
              });
            });
            this.chargeInfoProduit();
          });
        }
      });
  }

  isMarqVisible: boolean = true;
  onSelectionChange(event) {
    if (event.value) {
      this.isMarqVisible = false;
    }
  }

  erroPicto: boolean = false;
  addPicto() {
    this.erroPicto = this.pictoList.length < 2;
    if (
      this.getValueForm("pictoName").length <= 30 &&
      this.getValueForm("pictoName") != "" &&
      this.pictoList.findIndex(
        (picto) => picto.picto_id === this.getValueForm("pictoIcon").id
      ) == -1 &&
      this.pictoList.length < 4
    ) {
      this.pictoList.push({
        picto_id: this.getValueForm("pictoIcon").id,
        url: this.getValueForm("pictoIcon").url,
        picto_text: this.getValueForm("pictoName"),
      });
      this.erroPicto = this.pictoList.length < 2;
      this.formGroupe.controls["pictoName"].setValue("");
      this.selectedPicto = null;
      this.isMarqVisible = true;
    }
  }

  removePicto(id) {
    const index = this.pictoList.findIndex((picto) => picto.picto_id === id);
    if (index !== -1) {
      this.pictoList.splice(index, 1);
      this.erroPicto = this.pictoList.length < 2;
    }
  }

  asPromo: boolean = false;
  togglePromo() {
    this.asPromo = !this.asPromo;
    if (this.asPromo) {
      this.formGroupe.addControl("prix2", new FormControl(""));
      this.formGroupe.addControl("prix3", new FormControl(""));
    } else {
      this.formGroupe.removeControl("prix2");
      this.formGroupe.removeControl("prix3");
    }
  }

  showCaracs(item) {
    const caracsToShow = this.retourService.caracs
      ? this.retourService.caracs.map((e) => e.cat_car_id)
      : [];
    return true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.listCaracteristique &&
      changes.listCaracteristique.currentValue
    ) {
      this.listCaracteristique.forEach((elt) => {
        if (
          elt.caracteristique.type === "SELECT" &&
          elt.caracteristique.select_multiple
        ) {
          elt.valeurs.forEach((element) => (element.text = element.valeur));
        }
        if (elt.caracteristique.is_required) {
          if (elt.caracteristique.type === "NUMBER") {
            this.formGroupe.addControl(
              "caracteristique" + elt.cat_car_id,
              new FormControl("0", [Validators.required])
            );
          } else {
            this.formGroupe.addControl(
              "caracteristique" + elt.cat_car_id,
              new FormControl("", [Validators.required])
            );
          }
        } else {
          if (elt.caracteristique.type === "NUMBER") {
            this.formGroupe.addControl(
              "caracteristique" + elt.cat_car_id,
              new FormControl("0")
            );
          } else {
            this.formGroupe.addControl(
              "caracteristique" + elt.cat_car_id,
              new FormControl("")
            );
          }
        }
        if (elt.caracteristique && elt.caracteristique.unite !== "") {
          if (elt.caracteristique.is_required) {
            this.formGroupe.addControl(
              "caracteristique" + elt.cat_car_id + "unite",
              new FormControl("", [Validators.required])
            );
          } else {
            this.formGroupe.addControl(
              "caracteristique" + elt.cat_car_id + "unite",
              new FormControl("")
            );
          }
          const obj = {};
          obj["caracteristique" + elt.cat_car_id + "unite"] =
            elt.caracteristique.unite !== "SELECT"
              ? elt.caracteristique.unite
              : "";
          this.formGroupe.patchValue(obj);
        }
      });
    }
    // if (this.productEdit) { setTimeout(() => { this.chargeInfoProduit(); }, 500); }
  }

  chargeInfoProduit() {
    const response = this.retourService;
    if (response.data) {
      this.formGroupe.controls["prixPromotion"].setValue(
        response.data.prix_promotion
      );
      this.formGroupe.controls["prixNormal"].setValue(response.data.prix);
      if (response.data.prix2 && response.data.prix3) {
        this.asPromo = true;
        ["prix2", "prix3"].forEach((key) => {
          this.formGroupe.addControl(key, new FormControl(response.data[key]));
        });
      }
      let obj = {};
      response.data.sous.forEach((element, index) => {
        // obj = {};
        // index = index || 0;
        obj["id"] = index;
        if (element.couleur) {
          this.formGroupe.addControl(
            "couleur" + index,
            new FormControl("", [Validators.required])
          );
          const existeColor = this.listCouleur.filter(
            (elt) => elt.id === element.couleur.id
          );
          if (existeColor.length) {
            obj["couleur" + index] = existeColor[0];
          } else {
            obj["couleur" + index] = element.couleur;
          }
          this.initialColors.push(index);
          // this.initialColors.push(element.couleur.id);
        } else {
          this.formGroupe.addControl("couleur" + index, new FormControl(""));
        }
        obj["taille" + index] = [];
        obj["listTailleDispo"] = [];
        obj["listTailleDispo1"] = [];
        console.log('element.sous_produits: ', element.sous_produits);
        
        element.sous_produits.forEach((sousProd, idx) => {
          idx = idx || 0;
          if (!sousProd.taille) {
            this.formGroupe.addControl("taille" + index, new FormControl(""));
            if (this.productEdit) {
              this.formGroupe.addControl("pieceDisponnible" + index, new FormControl(""));
            } else {
              this.formGroupe.addControl("pieceDisponnible" + index, new FormControl("", Validators.min(1)));
            }
            obj["pieceDisponnible" + index] = sousProd.disponibilite || 0;
          } else {
            console.log('with taille: ', sousProd.taille.id + ":" + sousProd.disponibilite);
            this.formGroupe.addControl("taille" + index, new FormControl("", [Validators.required]));
            obj["taille" + index].push("" + (sousProd.taille ? sousProd.taille.id : 0));
            if (this.productEdit) {
              this.formGroupe.addControl(index + "tailleDispo" + sousProd.taille.id, new FormControl(""));
            } else {
              this.formGroupe.addControl(index + "tailleDispo" + sousProd.taille.id, new FormControl("", Validators.min(1)));
            }
            obj[index + "tailleDispo" + sousProd.taille.id] = sousProd.disponibilite || 0;
            sousProd.taille.idSousProduit = sousProd.id;
            obj["listTailleDispo"] = [...obj["listTailleDispo"], ...[sousProd.taille]];
            obj["listTailleDispo1"] = [...obj["listTailleDispo"], ...[sousProd.taille]];
          }
        });
        obj["listImages"] = element.images || [];
        obj["listImages"].forEach((img) => (img.urlImg = img.nom));
        obj["listImage" + index] = element.images.length ? 1 : 0;
        obj["nbImages"] = obj["listImages"].length;
        this.listCouleurDispo.push(Object.assign({}, obj));
      });

      // let taillesTmp = [];
      this.listCouleurDispo.forEach((value, key) => {
        const tailleKey = "taille" + key;
        value.listImages.forEach((imgElt, imgInd) => {
          imgElt['order']= imgInd + 1;
        });
        if (value[tailleKey]) {
          const ids = value[tailleKey].map((e) => +e);
          let tab = [];
          this.listTailles.forEach((e) => {
            const o = { ...e };
            if (ids.includes(e.taille_id)) {
              o.disabled = true;
              o.selected = true;
            }
            tab.push(o);
          });
          this.taillesPerItem.push(tab);
        }
      });
      response.data.caracs.forEach((elt) => {
        // New code
        const caracteristique = this.listCaracteristique.filter(
          (car) =>
            car.caracteristique &&
            elt.caracteristique &&
            car.caracteristique.code === elt.caracteristique.code
        );
        caracteristique.forEach((cat) => (elt.cat_car_id = cat.cat_car_id));
        if (elt.caracteristique.type === "SELECT") {
          // elt.valeurs.forEach(element => element.text = element.valeur);
          //  'caracteristique' + item.cat_car_id
          if (elt.caracteristique.select_multiple) {
            elt.valeurs.forEach((event) => {
              event.text = event.valeur;
              this.changeMultiple(
                event,
                "caracteristique" + elt.cat_car_id,
                elt
              );
            });
          } else if (elt.caracteristique.seect_multiple) {
            elt.caracteristique["select_multiple"] = true;
            const aaa = [];
            elt.valeurs.forEach((event) => {
              event.text = event.valeur;
              aaa.push("" + event.id);
            });
            this.changeMultiple(aaa, "caracteristique" + elt.cat_car_id, elt);
          } else {
            obj["caracteristique" + elt.cat_car_id] = parseInt(elt.valeur, 10);
          }
        } else {
          obj["caracteristique" + elt.cat_car_id] = elt.valeur;
          if (elt.caracteristique && elt.caracteristique.unite !== "") {
            if (elt.caracteristique.is_required) {
              this.formGroupe.addControl(
                "caracteristique" + elt.cat_car_id + "unite",
                new FormControl("", [Validators.required])
              );
            } else {
              this.formGroupe.addControl(
                "caracteristique" + elt.cat_car_id + "unite",
                new FormControl("")
              );
            }
            obj["caracteristique" + elt.cat_car_id + "unite"] = parseInt(
              elt.unite,
              10
            );
            // obj['caracteristique' + elt.cat_car_id + 'unite'] = elt.unite;
          }
        }
      });
      
      
      this.formGroupe.addControl("deleted_image_ids", new FormControl([]));
      
      this.formGroupe.patchValue(obj);

      this.taillesPerItem.forEach((value, key) => {
        setTimeout(() => {
          this.removeClearOption(key);
        }, 1000);
      });

      console.log('listCouleurDispo: ', this.listCouleurDispo);
      
    }
  }

  orderChange($event: any, item: any, parent: any) {
    parent['edited'] = true;
    item.order = $event.data;
    item["edited"] = true;
  }

  orderUpdate(item: any) {
    item['edited'] = false;
    console.log('images ordered: ', item);
    const formData = new FormData();
    item.listImages.forEach((orderElt, ind) => {
      formData.set(`imgs[${ind}].order`, orderElt.order);
      formData.set(`imgs[${ind}].img_id`, orderElt.id);
    });
    this.httpClient.post(`${environment.ws_url}image-produits/update-order`, formData).subscribe((dataMenu: any) => {
      console.log('order updated');
    });
  }

  removeClearOption(id) {
    if (this.taillesPerItem[id]) {
      const tab = this.taillesPerItem[id].filter((e) => e.disabled);
      if (tab.length > 0) {
        const valeurs = tab.map((e) => e.valeur);
        const elements = $(
          "#taille" +
            id +
            " ul.select2-selection__rendered .select2-selection__choice"
        );
        elements.each((index, element) => {
          const title = $(element).attr("title");
          if (valeurs.includes(title)) {
            const span = $(element).find(
              "span[class='select2-selection__choice__remove']"
            );
            $(span).css("display", "none");
          }
        });
      }
    }
  }

  imgListError: boolean = false;
  isLengthImages() {
    let error = false;
    this.listCouleurDispo.forEach((element) => {
      if (element.listImages.length < 3) {
        error = true;
        return;
      }
    });
    return error;
  }
  
  formSubmitted = false;
  async validerStep() {
    this.erroPicto = this.pictoList.length < 2;
    this.imgListError = this.isLengthImages();
    this.formSubmitted = true;
    
    if (!this.formGroupe.invalid && !this.showSpinner && !this.erroPicto && !this.isLengthImages()) {
      this.showError = false;
      this.showSpinner = true;
      if (!this.productEdit) {
        const formData = new FormData();
        formData.set(`prix_promotion`, this.getValueForm("prixPromotion") || 0);
        formData.set(`prix`, this.getValueForm("prixNormal") || 0);
        formData.set(`prix2`, this.getValueForm("prix2") || 0);
        formData.set(`prix3`, this.getValueForm("prix3") || 0);
        formData.set(`produit`, this.produit.id.produitId);
        
        //PICTO ADD
        let idx = 0;
        this.pictoList.forEach((picto) => {
          formData.set(`pictos[${idx}].picto_id`, picto.picto_id);
          formData.set(`pictos[${idx}].picto_text`, picto.picto_text);
          idx++;
        });

        let newInd = 0;
        this.listCaracteristique?.forEach((carac, index) => {
          let valeur = this.getValueForm("caracteristique" + carac.cat_car_id);
          if (valeur && valeur != 0) {
            formData.set(
              `caracs[${newInd}].categorie_caracteristique_id`,
              carac.cat_car_id
            );
            if (carac.caracteristique.select_multiple) {
              valeur = valeur || [];
              const valeurMultiple = this.getValueForm(
                "caracteristique" + carac.cat_car_id + "valeur"
              );
              formData.set(`caracs[${newInd}].valeur`, valeurMultiple || "");
            } else {
              formData.set(`caracs[${newInd}].valeur`, valeur || 0);
            }
            if (carac.caracteristique.unite !== "") {
              const unite = this.getValueForm(
                "caracteristique" + carac.cat_car_id + "unite"
              );
              formData.set(`caracs[${newInd}].unite`, unite);
            }
            const unite2 = this.getValueForm(
              "caracteristique" + carac.cat_car_id + "unite"
            );
            newInd += 1;
          }
        });

        this.listCouleurDispo?.forEach((elt, index) => {
          const couleur = this.getValueForm("couleur" + index);
          const arrayImg = [];
          const imgObj = "listImages";
          elt[imgObj] = elt[imgObj] || [];
          if (elt[imgObj].length) {
            elt[imgObj].forEach((img) => {
              arrayImg.push(img.file);
            });
          } else {
            this.showSpinner = false;
            this.showError = true;
            return false;
          }
          arrayImg?.forEach((eltImg: any, indImg: number) => {
            formData.append(`sous[${index}].images[${indImg}]`, eltImg);
          });
          const dispo = this.getValueForm("pieceDisponnible" + index);
          if (this.produit.categorie.has_color) {
            formData.set(`sous[${index}].couleur`, couleur.id || "");
          }
          formData.set(`sous[${index}].disponibilite`, dispo || 0);
          const tailles = elt["listTailleDispo"] || [];
          tailles?.forEach((taille, idx) => {
            const tailleDispo = this.getValueForm(
              index + "tailleDispo" + taille.id
            );
            formData.set(
              `sous[${index}].tailles[${idx}].taille`,
              taille.id || 0
            );
            formData.set(
              `sous[${index}].tailles[${idx}].disponibilite`,
              tailleDispo || 0
            );
          });
        });

        await this.httpClient.post(environment.ws_url + "produits/step-two", formData).subscribe((dataMenu: any) => {
          this.nextStep.emit(dataMenu.data);
        });
      } else {
        const formData = new FormData();
        formData.set(`id`, this.produit.id.produitId);
        formData.set(`prix_promotion`, this.getValueForm("prixPromotion") || 0);
        formData.set("prix", this.getValueForm("prixNormal") || 0);
        formData.set(`prix2`, this.getValueForm("prix2") || 0);
        formData.set(`prix3`, this.getValueForm("prix3") || 0);
        if (this.getValueForm("deleted_image_ids").length) {
          formData.set(`deleted_image_ids`, this.getValueForm("deleted_image_ids") || []);
        }
        let idx = 0;

        this.pictoList.forEach((picto) => {
          formData.set(`pictos[${idx}].picto_id`, picto.id || picto.picto_id);
          formData.set(`pictos[${idx}].picto_text`, picto.picto_text);
          idx++;
        });

        this.listCaracteristique.forEach((carac, index) => {
          let valeur = this.getValueForm("caracteristique" + carac.cat_car_id);
          formData.set(
            `up_caracs[${index}].caracs_value_id`,
            this.retourService.data.caracs[index].caracs_valeur_id
          );
          if (carac.caracteristique.select_multiple) {
            valeur = valeur || [];
            const valeurMultiple = this.getValueForm(
              "caracteristique" + carac.cat_car_id + "valeur"
            );
            formData.set(
              `up_caracs[${index}].valeur`,
              valeurMultiple ||
                this.retourService.data.caracs[index].valeurs.map(
                  (elt) => elt.id
                )
            );
          } else {
            formData.set(`up_caracs[${index}].valeur`, valeur || "");
          }
          if (carac.caracteristique.unite !== "") {
            const unite = this.getValueForm(
              "caracteristique" + carac.cat_car_id + "unite"
            );
            formData.set(`up_caracs[${index}].unite`, unite);
          }
        });

        let addNew = 0;
        let idxModif = 0;
        let tailleIndex = 0;
        let tailleAddIndex = 0;
        let idxAddTaille = 0;
        let imgNewIndex = 0;
        
        this.listCouleurDispo.forEach((elt, index) => {
          const couleur = this.getValueForm("couleur" + index);
          const arrayImg = [];
          let idxAddCoul = 0;

          if (elt.hasOwnProperty("isAdd")) {
            console.log('this.listCouleurDispo new: ', this.listCouleurDispo);
            // this.listCouleurDispo.forEach((elt, index) => {
            const couleur = this.getValueForm("couleur" + index);
            if (this.produit.categorie.has_color && couleur.id) {
              formData.set(`sous[${idxAddCoul}].couleur`, couleur.id);
            }
            const arrayImg = [];
            const imgObj = "listImages";
            elt[imgObj] = elt[imgObj] || [];
            if (elt[imgObj].length) {
              elt[imgObj].forEach((img) => {
                arrayImg.push(img.file);
                // formData.append(`sous[${idxAddCoul}].images`, img.file);
              });
            } else {
              // this.showSpinner = false;
              this.showError = true;
              return false;
            }

            arrayImg?.forEach((eltImg: any, indImg: number) => {
              formData.append(`sous[${idxAddCoul}].images[${indImg}]`, eltImg);
            });
            const dispo = this.getValueForm("pieceDisponnible" + index);
            if (this.produit.categorie.has_color) {
              formData.set(`sous[${idxAddCoul}].couleur`, couleur.id || "");
            }
            const tailles = elt["listTailleDispo"] || [];
            if (tailles && tailles.length) {
              tailles?.forEach((taille, idx) => {
                const tailleDispo = this.getValueForm(
                  index + "tailleDispo" + taille.id
                );
                formData.set(`sous[${idxAddCoul}].disponibilite`, dispo || 0);
                formData.set(
                  `sous[${idxAddCoul}].tailles[${idx}].taille`,
                  taille.id || 0
                );
                formData.set(
                  `sous[${idxAddCoul}].tailles[${idx}].disponibilite`,
                  tailleDispo || 0
                );
              });
            } else {
              formData.append(`sous[${idxAddCoul}].disponibilite`, dispo || 0);
            }
            idxAddCoul += 1;
            // });
          } else {
            // edit or add image
            if (elt["listImages"].length) {
              console.log('elt img: ', elt["listImages"]);
              
              elt["listImages"].forEach((imgElt, imgIndex) => {
                if (imgElt.file) {
                  if (couleur) {
                    formData.set(`image_prods[${imgNewIndex}].couleur`, couleur.id);
                  }
                  formData.set(`image_prods[${imgNewIndex}].images[${imgIndex}]`, imgElt.file);
                  imgNewIndex++;
                }
              });
            }

            // tailles
            // const tailles = elt["listTailleDispo"] || [];
            // if (tailles.length) {
            //   tailles.forEach(taille => {
            //     console.log('tailleIndex: ', tailleIndex);
            //     const tailleDispo = this.getValueForm(index + "tailleDispo" + taille.id) || 0;
            //     if (taille.idSousProduit) {
            //       formData.set(`dispos[${tailleIndex}].sous_produit`, taille.idSousProduit);
            //       formData.set(`dispos[${tailleIndex}].disponibilite`, tailleDispo);
            //       tailleIndex++;
            //     }
            //     if (taille["id"] && taille["valeur"]) {
            //       formData.set(`add_tailles[${tailleAddIndex}].couleur`, couleur.id);
            //       formData.set(`add_tailles[${tailleAddIndex}].tailles[0].taille`, taille.id);
            //       formData.set(`add_tailles[${tailleAddIndex}].tailles[0].disponibilite`, tailleDispo);
            //       tailleAddIndex++;
            //     }
            //   });
            // }


            


            console.log('this.listCouleurDispo old: ', this.listCouleurDispo);
            const tailles = elt["listTailleDispo"] || [];
            console.log('tailles', tailles);
            if (tailles.length) {
              let addTailleSous = 0;
              tailles?.forEach((taille, idx) => {
                const tailleDispo = this.getValueForm(
                  index + "tailleDispo" + taille.id
                );
                const tailleDispoFils = this.getValueForm(
                  index + "tailleDispo" + taille.id
                );
                if (taille.idSousProduit) {
                  formData.set(
                    `dispos[${idxModif}].sous_produit`,
                    taille.idSousProduit
                  );
                  formData.set(
                    `dispos[${idxModif}].disponibilite`,
                    tailleDispo || 0
                  );
                  idxModif++;
                }
                if (!taille["id"] && !taille["valeur"]) {
                  formData.set(
                    `sous[${index}].tailles[${idx}].taille`,
                    taille.id || 0
                  );
                  formData.set(
                    `sous[${index}].tailles[${idx}].disponibilite`,
                    tailleDispo || 0
                  );
                } else {
                  if (taille.idSousProduit && this.productEdit) {
                    formData.set(
                      `dispos[${index}].tailles[${idx}].taille`,
                      taille.id || 0
                    );
                    formData.set(
                      `dispos[${index}].tailles[${idx}].disponibilite`,
                      tailleDispo || 0
                    );
                  } else {
                    // formData.set(`add_tailles[${addNew}].tailles[${addTailleSous}].taille`, taille.id || 0);
                    // formData.set(`add_tailles[${addNew}].tailles[${addTailleSous}].disponibilite`, tailleDispo || 0);
                    formData.set(
                      `add_tailles[${addNew}].tailles[0].taille`,
                      taille.id || 0
                    );
                    formData.set(
                      `add_tailles[${addNew}].tailles[0].disponibilite`,
                      tailleDispo || 0
                    );
                    if (couleur.id) {
                      formData.set(`add_tailles[${addNew}].couleur`, couleur.id);
                      addNew++;
                    }
                    addTailleSous++;
                  }
                }
              });
            } else {
              const dispo = this.getValueForm("pieceDisponnible" + index);
              formData.set(`dispos[${index}].disponibilite`, dispo || 0);
              formData.set(
                `dispos[${index}].sous_produit`,
                this.retourService.data.sous[index].sous_produits[0].id
              );
            }

            // const imgObj = "listImages";
            // elt[imgObj] = elt[imgObj] || [];
            // const newImg = false;
            // if (elt[imgObj].length > elt["nbImages"]) {
            //   if (couleur) {
            //     formData.set(`image_prods[${index}].couleur`, couleur.id);
            //   }
            //   elt[imgObj].forEach((img) => {
            //     if (img.file) {
            //       arrayImg.push(img.file);
            //       if (arrayImg.length) {
            //         arrayImg?.forEach((eltImg: any, indImg: number) => {
            //           formData.set(
            //             `image_prods[${index}].images[${indImg}]`,
            //             eltImg
            //           );
            //         });
            //       }
            //     }
            //   });
            // } else {
            //   // this.showSpinner = false;
            //   this.showError = true;
            //   return false;
            // }
            idxModif += 1;
          }
        });

        // console.log('formgroup', this.formGroupe.value);
        // formData.forEach((value,key) => {
        //   console.log(key+" "+value)
        // });

        // this.showSpinner = false;
        // this.formSubmitted = false;
        

        this.httpClient.post(`${environment.ws_url}produits/HADEEN/${this.productEdit}`, formData).subscribe(
          (dataMenu: any) => {
            this.toastr.success("Votre produit a bien été modifié", "", {
              timeOut: 3000,
            });
            this.showSpinner = false;
            this.router.navigate(['/compte/ma-boutique']);
            // this.nextStep.emit(dataMenu.data);
          },
          (err: any) => {
            this.showSpinner = false;
            this.showError = true;
          }
        );
      }
    } else {
      this.showError = true;
    }
  }

  fileProgress(fileInput: any, item) {
    const fileData = {};
    fileData["file"] = fileInput.target.files[0];
    const mimeType = fileData["file"].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    item.listImages = item.listImages || [];
    const reader = new FileReader();
    // tslint:disable-next-line: no-string-literal
    reader.readAsDataURL(fileData["file"]);
    const that = this;
    reader.onload = (event) => {
      // tslint:disable-next-line: no-string-literal
      fileData["urlImg"] = reader.result;
      item.listImages.push(fileData);
      item.listImages = [...item.listImages];
      const obj = {};
      obj["listImage" + item.id] = item.listImages.length ? 1 : 0;
      that.formGroupe.patchValue(obj);
    };
    console.log('charge photo: ', item);
    
  }

  addFile(fileInput: any, item) {
    const fileData = {};
    item["file"] = fileInput.target.files[0];
    const mimeType = item["file"].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(item["file"]);
    const that = this;
    reader.onload = (event) => {
      item.isFile = true;
    };
  }

  addCouleur() {
    if (this.produit.has_color) {
      this.formGroupe.addControl(
        "couleur" + this.listCouleurDispo.length,
        new FormControl("", [Validators.required])
      );
    } else {
      this.formGroupe.addControl(
        "couleur" + this.listCouleurDispo.length,
        new FormControl("")
      );
    }
    if (!this.produit.has_taille) {
      this.formGroupe.addControl(
        "taille" + this.listCouleurDispo.length,
        new FormControl("")
      );
      if (this.productEdit) {
        this.formGroupe.addControl(
          "pieceDisponnible" + this.listCouleurDispo.length,
          new FormControl("")
        );
      } else {
        this.formGroupe.addControl(
          "pieceDisponnible" + this.listCouleurDispo.length,
          new FormControl("", Validators.min(1))
        );
      }
      const obj = {};
      obj["pieceDisponnible" + this.listCouleurDispo.length] = 1;
      this.formGroupe.patchValue(obj);
    } else {
      this.formGroupe.addControl(
        "taille" + this.listCouleurDispo.length,
        new FormControl("", [Validators.required])
      );
    }

    this.formGroupe.addControl(
      "listImage" + this.listCouleurDispo.length,
      new FormControl("", Validators.min(1))
    );
    const img = {};
    img["listImage" + this.listCouleurDispo.length] = 0;
    this.formGroupe.patchValue(img);

    const index = this.listCouleurDispo.length;
    this.listCouleurDispo.push({ id: index, listImages: [], isAdd: true });
    this.listCouleurDispo = [...this.listCouleurDispo];
  }

  remove(img, item) {
    item.listImages = item.listImages || [];
    item.listImages = item.listImages.filter((elt) => elt !== img);
    const obj = {};
    obj["listImage" + item.id] = item.listImages.length ? 1 : 0;
    if (img && img.id) {
      let imgDeleted = this.getValueForm("deleted_image_ids");
      obj["deleted_image_ids"] = [img.id, ...imgDeleted]
    }
    this.formGroupe.patchValue(obj);
  }

  affiche() {}

  getValueForm(item) {
    return this.formGroupe.value[item];
  }

  getColor(item) {
    return this.formGroupe.value[item] || {};
  }

  setTailleDispo(event, item) {
    const id = "taille" + item.id;
    event = event || [];
    if (this.productEdit) {
      this.removeClearOption(item.id);
    }
    item.listTailleDispo = item.listTailleDispo || [];
    const listTaille = [];
    this.listTailles.forEach((taille) => {
      event.forEach((elt: any) => {
        if (parseInt(elt, 10) === taille.id) {
          const existe = item.listTailleDispo.filter(
            (dispo) => dispo.id === taille.id
          );
          listTaille.push(taille);
          if (!existe.length) {
            this.formGroupe.addControl(
              item.id + "tailleDispo" + taille.id,
              new FormControl("", Validators.min(1))
            );
            const obj = {};
            const tailleDispo = this.getValueForm(
              item.id + "tailleDispo" + taille.id
            );
            obj[item.id + "tailleDispo" + taille.id] = tailleDispo | 0;
            obj[item.id + "tailleDispo" + taille.id] = 0;
            this.formGroupe.patchValue(obj);
          }
        }
      });
    });
    // item.listTailleDispo = [...item.listTailleDispo, ...listTaille];

    const nonSuppr = [];
    if (item.listTailleDispo1 && item.listTailleDispo1.length) {
      item.listTailleDispo1.forEach((elt) => {
        const existe = nonSuppr.filter((e) => e.id === elt.id);
        if (!existe || (existe && !existe.length)) {
          nonSuppr.push(elt);
        }
      });
    }
    item.listTailleDispo = [...nonSuppr, ...listTaille];
    // console.log('edit?: --------- ', this.productEdit);
    // if (this.productEdit) {
    //   item.listTailleDispo = [...nonSuppr, ...listTaille];
    // } else {
    //   // item.listTailleDispo = [...listTaille];
    //   item.listTailleDispo = [...item.listTailleDispo1, ...listTaille];
    //   alert('test')
    // }

    this.listTailles.forEach((taille) => {
      const formControl = this.formGroupe.get(
        `${item.id}tailleDispo${taille.id}`
      );
      // const formControl2 = this.formGroupe.get(`taille${item.id}`);
      const isExiste = event.filter((dispo) => dispo === String(taille.id));
      if (formControl && !isExiste.length) {
        // this.formGroupe.removeControl(item.id + 'tailleDispo' + taille.id);
        // this.formGroupe.controls[item.id + 'tailleDispo' + taille.id].setValidators([]);
        // this.formGroupe.controls[item.id + 'tailleDispo' + taille.id].updateValueAndValidity();
        this.formGroupe.controls[
          item.id + "tailleDispo" + taille.id
        ].setValidators([]);
        this.formGroupe.controls[
          item.id + "tailleDispo" + taille.id
        ].updateValueAndValidity();
      }
    });
    if (this.productEdit) {
      // this.formGroupe.addControl('taille' + item.id, new FormControl(''));
      this.formGroupe.controls["taille" + item.id].setValidators([]);
      this.formGroupe.controls["taille" + item.id].updateValueAndValidity();
    }
  }

  getTailles(item, id) {
    const listTaille = [];
    if (this.formGroupe.value[item]) {
      this.listTailles.forEach((taille) => {
        this.formGroupe.value[item].forEach((eltItem: any) => {
          if (parseInt(eltItem, 10) === taille.id) {
            listTaille.push(taille);
          }
        });
      });
    }
    return listTaille;
  }

  onKeyDown(event) {
    const e = event;
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  changeMultiple(event, item, carac) {
    const valeur = event || [];
    let valeurMultiple = "";
    valeur.forEach((val) => {
      const retourVal = carac.valeurs.filter((i) => i.id === parseInt(val, 10));
      if (retourVal.length) {
        valeurMultiple =
          valeurMultiple +
          (valeurMultiple.length ? "," + retourVal[0].id : retourVal[0].id);
      }
    });
    this.formGroupe.addControl(item + "valeur", new FormControl(""));
    const obj = {};
    obj[item] = event;
    obj[item + "valeur"] = valeurMultiple;
    this.formGroupe.patchValue(obj);
  }

  isInvalid(item) {
    const control = this.formGroupe.get(item);
    return control?.invalid;
  }

  calculMontant() {
    const fff = this.formGroupe.value;
    if (fff.prixPromotion) {
      return parseFloat(
        String(
          fff.prixPromotion -
            (fff.prixPromotion * this.vendeur.vendeur.commission) / 100
        )
      ).toFixed(2);
    } else {
      return parseFloat(
        String(
          fff.prixNormal -
            (fff.prixNormal * this.vendeur.vendeur.commission) / 100
        )
      ).toFixed(2);
    }
  }

  removeCouleur(itemcolor) {
    this.formGroupe.removeControl("couleur" + itemcolor.id);
    this.formGroupe.removeControl("taille" + itemcolor.id);
    this.formGroupe.removeControl("pieceDisponnible" + itemcolor.id);
    this.formGroupe.removeControl("listImage" + itemcolor.id);
    this.listCouleurDispo = this.listCouleurDispo.filter(
      (elt) => elt !== itemcolor
    );
  }
}
