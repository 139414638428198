<div class="container-fluid" *ngIf="dataPage4">
  <div id="Top"></div>
  <!-- <h1 class="d-none">{{dataPage4.h1 ? dataPage4.h1 : dataPage4.Title}}</h1> -->

  <div id="{{item.IDs}}" class="{{item.Classes}} row" *ngFor="let item of dataPage4.Builder; let indBuilder = index">
    <!-- Hero -->
    <app-hero [item]="item" [indBuilder]="indBuilder" *ngIf="item.__component === 'composants.hero'"
      style="position: relative;" [ngStyle]="{'background-color': !item.isFirst && screenWidth<500 ? '#EFEFEF' : 'white'}"
      class="container" [ngClass]="{'mt-box': !item.isFirst}"></app-hero>

    <!-- MiniCardGroup -->
    <app-minicard-group [item]="item" [indBuilder]="indBuilder" [screenWidth]="screenWidth"
      *ngIf="item.__component === 'composants.mini-card-group'" class="container"></app-minicard-group>

    <!-- Text And Media OK-->
    <div class="container" *ngIf="item.__component === 'composants.text-and-media'" style="margin-top: 75px;">
      <div class="row text-and-media">
        <div class="text-and-media__text" id="{{ item.Content.IDs }}"
          [ngClass]="item.Content.Left_right === 'Right' ? 'order-2 col-md-9': 'order-1 col-md-7'">
          <h3 *ngIf="item.Content.titre" class="text-and-media__text--title"
            [ngClass]="item.Content.Left_right === 'Right' ? 'text-right': 'text-left'">
            {{ item.Content.titre }}</h3>
          <h2 *ngIf="item.Content.sousTitre" class="text-and-media__text--subTitle"
            [ngClass]="item.Content.Left_right === 'Right' ? 'text-right': 'text-left'">{{ item.Content.sousTitre }}</h2>
          <p class="text-and-media__text--description"
            [ngClass]="item.Content.Left_right === 'Right' ? 'text-right': 'text-left'">{{ item.Content.description }}
          </p>
        </div>

        <div class="text-and-media__media"
          [ngClass]="item.Content.Left_right !== 'Right' ? 'order-2 order-1 col-md-5': 'order-1 order-1 col-md-3'">
          <div class="text-and-media__media--img" [ngClass]="item.Content.Left_right !== 'Right' ? 'text-md-left': 'text-md-left'" *ngIf="item.Image">
            <img width="auto" height="auto" src="https://strapi.hadeen-place.fr{{ item.Image.url}}" alt="" style="max-height: 150px;">
          </div>

          <div class="row ml-auto" *ngIf="item.Teams" style="width: fit-content; padding-right: 15px;"
            [ngStyle]="{'float': item.Content.Left_Rigt === 'Right' ? '' : 'right'}">
            <div class="rounded-circle" *ngFor="let itemTeam of item.Teams, let i=index"
              style="width: 50px; height: 50px; background-color: #ffffff; padding: 3px;"
              [ngStyle]="{'margin-left': i != 0 ? '-10px' : '0px'}">
              <img width="auto" height="auto" class="w-100 h-100 rounded-circle" src="../../../assets/images/activityDefault.png" alt="">
            </div>
            <div class="rounded-circle mt-auto mb-auto" style="width: 30px; height: 30px;margin-left: -15px;">
              <img width="auto" height="auto" class="w-100 h-100 rounded-circle" src="../../../assets/images/Group_2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Only section intro OK -->
    <div class="container" *ngIf="item.__component === 'composants.section-intro-comp'" style="margin-top: 75px;">
      <div class="section-intro-comp" *ngIf="item.titre">
        <div class="section-intro-comp__text col-12 col-md-6">
          <h3 *ngIf="item.titre" class="section-intro-comp__text--title">{{ item.titre }}</h3>
          <h2 *ngIf="item.sousTitre" class="section-intro-comp__text--subTitle">{{ item.sousTitre }}</h2>
          <h4 *ngIf="item.description" class="section-intro-comp__text--description">{{ item.description }}</h4>
        </div>
        <div class="section-intro-comp__space col-12 col-md-6 m-auto">
        </div>
      </div>
    </div>

    <!-- Card -->
    <app-card [item]="item" class="container" *ngIf="item.__component === 'composants.cards-group'"
      style="margin-bottom: 25px;"></app-card>

    <div class="container" *ngIf="item.data">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 ml-auto mr-auto mb-3" *ngFor="let itemProd of item.data">
          <app-produit-input [data]="itemProd" [loginTemplate]="loginTemplate"></app-produit-input>
        </div>
      </div>
    </div>


    <!-- Vendeur list -->
    <div class="container" *ngIf="item.__component === 'composants.vendeur-group'">
      <div *ngIf="item.VendeurUnique && item.Vendeurs">
        <div class="row m-0 w-100" *ngFor="let itemSeller of item.Vendeurs" style="padding-top: 50px;">
          <div class="containt" style="position: relative; cursor: pointer;" *ngIf="itemSeller.data && itemSeller.data.user" [routerLink]="['/detail-vendeur/' + formatUrl(itemSeller.data.user.pseudo) + '-' + itemSeller.data.user.id ]">
            <img width="auto" height="auto" loading="lazy" *ngIf="itemSeller.data && itemSeller.data.boutique && itemSeller.data.boutique.logo" class="img rounded-circle d-block" [src]="itemSeller.data.boutique.logo" alt="" style="width: 113px; height: 113px; border: 1px solid #d9d9d9;">
            <img width="auto" height="auto" loading="lazy" *ngIf="itemSeller.data && itemSeller.data.boutique && !itemSeller.data.boutique.logo" class="img rounded-circle d-block" src="assets/static/av.png" alt="" style="width: 113px; height: 113px;">
            <h1 *ngIf="itemSeller.data && itemSeller.data.boutique">{{ itemSeller.data.boutique.nom }}</h1>
            <p *ngIf="itemSeller.data && itemSeller.data.boutique">{{ itemSeller.data.boutique.description }}</p>

            <div *ngIf="commonService.user">
              <ng-template #popTemplate2 class="text-right">
                <p class="text-left" style="font-size: 20px; font-weight: 500;">Contacter le vendeur</p>
                <mat-form-field class="w-100 hadeenInput">
                  <mat-label>Entrer votre message</mat-label>
                  <textarea matInput [(ngModel)]="myMessage2[itemSeller.id]" cdkTextareaAutosize cdkAutosizeMinRows="3"></textarea>
                </mat-form-field>
                <div class="text-right">
                  <button type="button" mat-flat-button (click)="sendMessage(itemSeller)"
                    style="background-color: #345b2b; border: none; color: #ffffff; margin-top: 7px; border-radius: 0em;">
                    Envoyer
                  </button>
                </div>
              </ng-template>
              <button *ngIf="item.Contacter" class="contact button" [popover]="popTemplate2" containerClass="contactPopover" adaptivePosition="false"
              #pop2="bs-popover">Contacter</button>
            </div>
            <button *ngIf="!commonService.user && item.Contacter" (click)="openLogin()" class="contact button">Contacter</button>
          </div>
        </div>
      </div>

      <div class="row m-auto" *ngIf="!item.VendeurUnique && item.Vendeurs" style="width: fit-content;">
        <div class="col-auto text-center" *ngFor="let itemSeller of item.Vendeurs">
          <div style="cursor: pointer;" *ngIf="itemSeller.data && itemSeller.data.user" [routerLink]="['/detail-vendeur/' + formatUrl(itemSeller.data.user.pseudo) + '-' + itemSeller.data.user.id ]">
            <img width="auto" height="auto" *ngIf="itemSeller.data && itemSeller.data.boutique && itemSeller.data.boutique.logo" [src]="itemSeller.data.boutique.logo" alt="" style="border-radius: 50%; width: 100px; height: 100px;">
            <img width="auto" height="auto" *ngIf="itemSeller.data && itemSeller.data.boutique && !itemSeller.data.boutique.logo" src="assets/static/av.png" alt="" style="border-radius: 50%; width: 100px; height: 100px;">
            <p class="mt-2 text-center" *ngIf="itemSeller.data && itemSeller.data.boutique">{{ itemSeller.data.boutique.nom }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Video Media OK -->
    <div class="container" *ngIf="item.__component === 'composants.youtube-embed'"
      style="margin-top: 25px; margin-bottom: 25px;">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]="item.YoutubeEmbed | safe" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>

    <!-- Testimonials OK -->
    <div *ngIf="item.__component === 'composants.testims-group'" id="test"
      style="margin-top: 25px; margin-bottom: 25px;" [ngClass]="item.FullWidth ? 'container-fluid' : 'container'">
      <div class="testimonials-group__content">
        <div class="testimonials-group__item">
          <div class="col-12 col-md-4 mb-3 mb-md-0"
            *ngFor="let itemTestimonials of item.testimonials">
            <app-testimonial class="" [itemTestimonials]="itemTestimonials"></app-testimonial>
          </div>
        </div>
      </div>
    </div>

    <!-- Media And Text -->
    <div class="container-fluid" *ngIf="item.__component === 'composants.media-and-text'"
      [ngStyle]="{'background': item.BackgroundImage ? 'url(https://strapi.hadeen-place.fr' + item.BackgroundImage.url +')' : item.BackgroundColor ? item.BackgroundColor : '', 'background-position': 'center'}"
      style="margin-top: 25px; margin-bottom: 25px; padding-top: 20px; padding-bottom: 20px;">
      <div class="container">
        <div class="media-and-text row">
          <div class="media-and-text__media col-12 col-md-4" [ngClass]="{'order-2': item.TitreSection.Left_right === 'Right'}">
            <div class="pr-0" *ngIf="item.MapEmbed" leaflet id="mapid2"
              style="border-radius: 10px; height: 370px; z-index: 0;"></div>
            <div class="pr-0" *ngIf="item.Slider" style="border-radius: 10px; z-index: 0;">
              <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item" *ngFor="let itemSlider of item.Slider; let i=index"
                    [ngClass]="{'active': i === 0}">
                    <div class="h-100" uk-lightbox="animation: slide">
                      <div style="height: 350px;">
                        <a class="uk-inline w-100" href="https://strapi.hadeen-place.fr{{ itemSlider.url }}" rel="nofollow"
                          data-caption="Caption 1" style="height: inherit;">
                          <img width="auto" height="auto" loading="lazy" class="w-100" src="https://strapi.hadeen-place.fr{{ itemSlider.url }}" alt=""
                            style="border-radius: 10px;">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="media-and-text__text col-12 col-md-7 pr-0" [ngClass]="{'order-1': item.TitreSection.Left_right === 'Right'}" id="{{ item.TitreSection.IDs }}">
            <div class="row mt-4 mt-md-0">
              <div class="col-12 m-auto" style="font-size: 14px; font-weight: 700;">
                <h2 *ngIf="item.TitreSection.titre" [ngClass]="item.TitreSection.Alignement ? 'text-' + item.TitreSection.Alignement : 'text-left'" style="font-size: 16px; font-weight: 700; color: #2E2E2E; margin-top: 5px; font-size: 'Segoe UI';">
                  {{ item.TitreSection.titre }}</h2>
                <h3 *ngIf="item.TitreSection.sousTitre" [ngClass]="item.TitreSection.Alignement ? 'text-' + item.TitreSection.Alignement : 'text-left'" style="margin-bottom: 0px; line-height: 1.5rem; font-size: 1.25rem; font-weight: 700; color: #2e2e2e; margin-top: 5px;">
                  {{ item.TitreSection.sousTitre }}</h3>
              </div>
              <div class="col-12 col-md-7">
                <div class="row" *ngIf="item.Team" style="width: fit-content; margin-left: auto; margin-right: 15px;">
                  <div id="{{ itemTeam.IDs }}" class="rounded-circle" *ngFor="let itemTeam of item.Team, let i=index"
                    style="width: 45px; height: 45px; background-color: #ffffff; padding: 2px;"
                    [ngStyle]="{'margin-left': i != 0 ? '-10px' : '0px'}">
                    <img width="auto" height="auto" loading="lazy" *ngIf="itemTeam.Image" class="w-100 h-100 rounded-circle"
                      src="https://strapi.hadeen-place.fr{{ itemTeam.Image.url }}" alt="">
                  </div>
                  <div class="rounded-circle mt-auto mb-auto" style="width: 35px; height: 35px; margin-left: -10px;">
                    <img width="auto" height="auto" loading="lazy" class="w-100 h-100 rounded-circle" src="../../../assets/images/Group_2.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 30px;">
              <div class="col-12" style="font-size: 12px;">
                <p [ngClass]="item.TitreSection.Alignement ? 'text-' + item.TitreSection.Alignement : 'text-left'" style="font-size: 14px; font-weight: 400; color: #707070; margin-top: 5px;"
                  innerHtml="{{ item.TitreSection.description }}"></p>
              </div>
            </div>
            <p class="col-12 p-0" *ngIf="item.Link" id="{{ item.Link.IDs }}"
              [ngStyle]="{'text-align': item.Link.Align === 'Center' ? 'center' : item.Link.Align === 'Right' ? 'right' : 'left'}"
              style="margin-top: 70px;">
              <a *ngIf="item.Link.ExternalURL" href="{{ item.Link.ExternalURL }}" rel="nofollow">
                <button [ngStyle]="{'margin-left': item.Link.Align === 'Center' ? '35%' : item.Link.Align === 'Right' ? 'auto' : '0px'}"
                  [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'ButtonSecondary' ? 'h-btn-green-bordered' : 'h-btn-link'">
                  {{ item.Link.Title }}
                </button>
              </a>
              <a *ngIf="!item.Link.ExternalURL && item.Link.InternalURL"
                [routerLink]="['/Blog/' + item.Link.InternalURL.Title2]"
                routerLinkActive="router-link-active">
                <button [ngStyle]="{'margin-left': item.Link.Align === 'Center' ? '35%' : item.Link.Align === 'Right' ? 'auto' : '0px'}"
                  [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'ButtonSecondary' ? 'h-btn-green-bordered' : 'h-btn-link'">
                  {{ item.Link.Title }}
                </button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- DataBlock OK  class="container" -->
    <div [ngClass]="item.FullWidth ? 'container-fluid p-0 bg-primary' : 'container'"
      *ngIf="item.__component === 'composants.data-group'" style="margin-top: 25px; margin-bottom: 25px;">
      <div class="data-group" [ngStyle]="{'background-color': item.Boxed && item.BackgroundColor ? item.BackgroundColor : item.Boxed && !item.BackgroundColor ? '#007bff' : '#FFF'}">
        <div class="row">
          <div class="data-group__item col" [ngStyle]="{'color': item.Boxed ? '#ffffff' : '#007bff'}"
            *ngFor="let itemBlock of item.data_group.Data">
            <p class="data-group--value">
              {{ itemBlock.Number }}{{ itemBlock.Unit }}</p>
            <p class="data-group--caption">{{ itemBlock.Caption }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- OptinBlock OK -->
    <app-optin class="container" *ngIf="item.__component === 'composants.optin'" [dataItem]="item"
      style="margin-top: 75px; margin-bottom: 25px;"></app-optin>

    <!-- Questions group -->
    <div class="container" *ngIf="item.__component === 'composants.questions-group'"
      style="margin-top: 75px; margin-bottom: 25px;">
      <mat-accordion *ngIf="item.Questions" class="questions-group__questions">
        <mat-expansion-panel class="questions-group__question mb-3" (opened)="panelOpenState = true"
          (closed)="panelOpenState = false" *ngFor="let itemQuest of item.Questions">
          <mat-expansion-panel-header class="questions-group__title">
            <mat-panel-title style="font-size: 16px; font-weight: 700; color: #2E2E2E; margin-top: 5px;">
              {{ itemQuest.Title }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="questions-group__text" style="font-size: 14px;">
            <!-- {{ itemQuest.ExpandableContent }} -->
            <div [innerHTML]="itemQuest.ExpandableContent"></div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="questions-group__faqs" *ngIf="item.faq">
        <mat-expansion-panel class="questions-group__faq mb-3" (opened)="panelOpenState = true"
          (closed)="panelOpenState = false" *ngFor="let itemQuest of item.faq.Question">
          <mat-expansion-panel-header class="questions-group__title">
            <mat-panel-title style="font-size: 16px; font-weight: 700; color: #2E2E2E; margin-top: 5px;">
              {{ itemQuest.Title }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="questions-group__text" style="font-size: 14px;">
            <!-- {{ itemQuest.ExpandableContent }} -->
            <div [innerHTML]="itemQuest.ExpandableContent"></div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Contacter-nous -->
    <div class="container" *ngIf="item.__component === 'composants.contactez-nous'"
      style="margin-top: 25px; margin-bottom: 25px;">
      <app-contact [mailAdmin]="'jo.rakoto@vatilab.com'"></app-contact>
    </div>

    <!-- Expandable content -->
    <div class="container" *ngIf="item.__component === 'composants.expandable-content'" style="margin-top: 25px; margin-bottom: 25px">
      <app-expandable [item]="item"></app-expandable>
    </div>

    <!-- section texte OK -->
    <div class="container" *ngIf="item.__component === 'composants.section-texte'"
      style="margin-top: 25px; margin-bottom: 25px;" [ngStyle]="{
        'text-align' : item.Allignement === 'Centrer' ? 'center' : item.Allignement === 'Droite' ? 'right' : 'justify'
      }">
      <h1 *ngIf="item.titre" [ngClass]="item.Allignement === 'Centrer' ? 'text-center': item.Allignement === 'Droite' ? 'text-right': 'text-left'">{{ item.titre }}</h1>
      <div *ngIf="item.corps" style="overflow-x: hidden; white-space: pre-line;" [innerHTML]="item.corps"></div>
    </div>

    <!-- section heading -->
    <div class="container" *ngIf="item.__component === 'composants.heading'"
      style="margin-top: 25px; margin-bottom: 25px; text-align: justify;">
      <div *ngFor="let itemHead of item.HeadingTag">
        <h1 [class.d-none]="!itemHead.isVisible" [ngStyle]="{
          'color': itemHead.color || '#000', 'text-align' : itemHead.alignement || 'left', 'font-size': itemHead.taille + 'px'
        }" *ngIf="itemHead.content && itemHead.type === 'h1'">{{itemHead.content}}</h1>
        <h2 [class.d-none]="!itemHead.isVisible" [ngStyle]="{
          'color': itemHead.color || '#000', 'text-align' : itemHead.alignement || 'left', 'font-size': itemHead.taille ? itemHead.taille + 'px' : '32px'
        }" *ngIf="itemHead.content && itemHead.type === 'h2'">{{itemHead.content}}</h2>
        <h3 [class.d-none]="!itemHead.isVisible" [ngStyle]="{
          'color': itemHead.color || '#000', 'text-align' : itemHead.alignement || 'left', 'font-size': itemHead.taille + 'px'
        }" *ngIf="itemHead.content && itemHead.type === 'h3'">{{itemHead.content}}</h3>
        <h4 [class.d-none]="!itemHead.isVisible" [ngStyle]="{
          'color': itemHead.color || '#000', 'text-align' : itemHead.alignement || 'left', 'font-size': itemHead.taille ? itemHead.taille + 'px' : '20px'
        }" *ngIf="itemHead.content && itemHead.type === 'h4'">{{itemHead.content}}</h4>
        <h5 [class.d-none]="!itemHead.isVisible" [ngStyle]="{
          'color': itemHead.color || '#000', 'text-align' : itemHead.alignement || 'left', 'font-size': itemHead.taille ? itemHead.taille + 'px' : '16px'
        }" *ngIf="itemHead.content && itemHead.type === 'h5'">{{itemHead.content}}</h5>
        <h6 [class.d-none]="!itemHead.isVisible" [ngStyle]="{
          'color': itemHead.color || '#000', 'text-align' : itemHead.alignement || 'left', 'font-size': itemHead.taille ? itemHead.taille + 'px' : '16px'
        }" *ngIf="itemHead.content && itemHead.type === 'h6'">{{itemHead.content}}</h6>
        <p [class.d-none]="!itemHead.isVisible" [ngStyle]="{
          'color': itemHead.color || '#000', 'text-align' : itemHead.alignement || 'left', 'font-size': itemHead.taille + 'px'
        }" *ngIf="itemHead.content && itemHead.type === 'p'">{{itemHead.content}}</p>
      </div>
    </div>

    <!-- section Cta -->
    <div class="container " *ngIf="item.__component === 'composants.cta'"
    [ngStyle]="{
      'margin-top': '25px',
      'margin-bottom': '25px', 
      'text-align': 'justify', 
      'display': 'flex',
      'justify-content': item.positionnement === 'center' ? item.positionnement : item.positionnement === 'right' ? 'flex-end': 'flex-start' }"
      >
   
    <a class="card__footer--cta cta--one"[href]="item.urls"
    rel="nofollow" target="_blank" style="text-decoration: none;"> 
      <button class="h-btn-dore rounded-0 d-none d-md-block PoppinsRegular"
                [ngClass]="item.className"
                style="width: auto; font-size: 15px; padding: 7px 15px" 
                >{{ item.title }} 
      </button>
    </a>
    </div>
  </div>

  <div id="Bottom"></div>
</div>

<ng-template #loginTemplate>
  <app-login [modal]="chatService.modalRef2" [newsCustomer]="true"></app-login>
</ng-template>
