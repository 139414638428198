import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-detail-commande",
  templateUrl: "./detail-commande.component.html",
  styleUrls: ["./detail-commande.component.scss"],
})
export class DetailCommandeComponent implements OnInit, OnDestroy {
  commandeId;
  totalFrais;
  // isAccRef = false;
  isValidate = false;
  commandSeller = [];
  commadSellerById: any[];
  page = 1;

  expeditionForm: FormGroup;
  contactShopperByForm: FormGroup;
  contactModeratorForm: FormGroup;

  commandeSellerSubscription: Subscription;

  constructor(
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.commandeId = this.activatedRoute.snapshot.paramMap.get("idCommande");
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.p;
    });
    this.initForm();
    this.initComponent();
  }

  initForm() {
    this.contactShopperByForm = this.formBuilder.group({
      article: ["", Validators.required],
      message: ["", Validators.required],
    });

    this.expeditionForm = this.formBuilder.group({
      code: ["", Validators.required],
      url: ["", Validators.required],
    });

    this.contactModeratorForm = this.formBuilder.group({
      messages: ["", Validators.required],
    });
  }

  initComponent() {
    // this.productService.getCommandeAcheteur(this.page);
    this.productService.getCommandeVendeur(this.page);
    this.commandeSellerSubscription = this.productService.CommandeSellerSubject.subscribe((CommandSeller: any[]) => {
      this.commandSeller = CommandSeller;
      this.commadSellerById = this.commandSeller.filter(
        (elt) => elt.id === parseInt(this.commandeId, 10)
      );
      this.calculFraisLivraison();
    });
    this.productService.emitCommandeSellerSubject();

    if (this.commadSellerById[0]?.statut_vendeur === "ACCEPTEE") {
      this.isValidate = true;
    } else {
      this.isValidate = false;
    }
  }

  totalPriceCommande(products) {
    let total = 0;
    products.forEach((elt) => {
      total += elt.sous_produit.produit.prix;
    });
    return total;
  }

  //commande validate
  onValideCommande(id: number) {
    const etat: string = "ACCEPTEE";
    this.productService.validate_Refused_Commande(id, etat);
    this.initComponent();
    if (!this.isValidate) {
      this.isValidate = true;
    }

    this.productService.emitCommandeSellerSubject();
  }

  // commande refused
  onRefusedCommande(id: number) {
    const etat = "DECLINEE";
    this.productService.validate_Refused_Commande(id, etat);
    this.initComponent();
    // if(!this.isAccRef){
    //   this.isAccRef = true;
    // }
    this.productService.emitCommandeSellerSubject();
  }

  // prix
  prix(itemm) {
    // let prixAcheteur;
    // if (itemm.sous_produit.produit.prix_promotion && itemm.sous_produit.produit.prix_promotion > 0 ) {
    //   prixAcheteur = itemm.sous_produit.produit.prix_promotion;
    // } else {
    //   prixAcheteur = itemm.sous_produit.produit.prix;
    // }

    // return (prixAcheteur - ((itemm.sous_produit.produit.id.plateforme.commision * prixAcheteur) / 100));
    return itemm.prix_vendeur;
  }

  // envoi du lien d'expedtion
  onExpedition() {
    const formValue = this.expeditionForm.value;
    const codeExpedition = formValue["code"];
    const lienExpedition = formValue["url"];
    const id = this.commadSellerById[0].id;

    this.productService.expedition(id, codeExpedition, lienExpedition);
    this.initComponent();
    this.expeditionForm.patchValue({
      code: "",
      url: "",
    });
    if (this.isValidate) {
      //masque les champs lien et code lorsqu'on appuie sur expedier
      this.isValidate = false;
    }
    this.productService.emitCommandeSellerSubject();
  }

  //contact l'acheteur
  onContactShopperByCommande(itemCommadSellerById) {
    const form = this.contactShopperByForm.value;
    let article = form["article"];
    let message = form["message"];
    itemCommadSellerById = itemCommadSellerById.filter(
      (elt) => elt.sous_produit.produit.model === article
    );
    this.contactShopperByForm.patchValue({
      message: "",
    });
    const Message = {
      //receiver: 14, moderateur
      receiver: itemCommadSellerById[0].acheteur.id,
      objet: `Nom: ${itemCommadSellerById[0].sous_produit.produit.model}, Commande n°: ${itemCommadSellerById[0].id}, Produit n°: ${itemCommadSellerById[0].sous_produit.produit.id.produitId}`,
      message: message,
    };
    this.chatService.sendMessage(Message);
    this.productService.emitCommandeSellerSubject();
  }

  onContactModerator() {
    const from = this.contactModeratorForm.value;
    let messages = from["messages"];
    this.contactModeratorForm.patchValue({
      messages: "",
    });

    const Message = {
      receiver: 14,
      objet: "HADEEN",
      message: messages,
    };

    this.chatService.sendMessage(Message);
    this.productService.emitCommandeSellerSubject();
  }

  ngOnDestroy() {
    this.commandeSellerSubscription.unsubscribe();
  }

  calculFraisLivraison() {
    let totF = 0;
    this.commadSellerById.forEach((element) => {
      totF += element.frais_livraison;
    });
    this.totalFrais = totF;
  }
}
