<div class="spinner" style="position: fixed; top: 30%; z-index: 1;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<form class="col-12 addProduit p-1 p-md-3 w-100" [formGroup]="formGroupe" [ngClass]="{'formError': showError}" autocomplete="off">
  <div class="row" style="background-color: transparent;" *ngIf="vendeur">
    <ng-template ngFor let-item [ngForOf]="listCaracteristique">
      <div style="width: 100%;" [ngSwitch]="item.caracteristique.type">
        <ng-template [ngSwitchCase]="'SELECT'">
          <div class="col-12" *ngIf="item.caracteristique.select_multiple">
            <div class="field hadeenInput" [ngClass]="{'ng-invalid': isInvalid('caracteristique' + item.cat_car_id)}" *ngIf="item.caracteristique.select_multiple">
              <ng-select2 class="disabled" [data]="item.valeurs" [placeholder]="item.caracteristique.nom" [options]="options" [formControlName]="'caracteristique' + item.cat_car_id" [required]="item.is_required" (valueChanged)="changeMultiple($event, 'caracteristique' + item.cat_car_id, item)"></ng-select2>
              <label style="font-size: 12px; padding: 0px 5px; margin-bottom: 0px;">{{item.caracteristique.nom}}</label>
              <mat-icon class="icon-info" style="color: #999;position: absolute;right: 30px;bottom: 5px;" matSuffix mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="*Ajoutez tous les impacts correspondants à votre article.">info</mat-icon>
            </div>
          </div>
          <mat-form-field class="col-12 hadeenInput" *ngIf="!item.caracteristique.select_multiple">
            <mat-label>{{item.caracteristique.nom}}</mat-label>
            <mat-select [formControlName]="'caracteristique' + item.cat_car_id" (change)="affiche()" [required]="item.is_required">
              <mat-option *ngFor="let value of item.valeurs" [value]="value.id"> {{ value.valeur }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>

        <ng-template [ngSwitchCase]="'NUMBER'">
          <ng-template [ngIf]="item.caracteristique.unite === ''">
            <mat-form-field class="col-12 hadeenInput">
              <mat-label>{{item.caracteristique.nom}}</mat-label>
              <input matInput type="number" [formControlName]="'caracteristique' + item.cat_car_id" [placeholder]="item.caracteristique.nom" (change)="affiche()" [required]="item.is_required">
            </mat-form-field>
          </ng-template>
          <ng-template [ngIf]="item.caracteristique.unite !== ''">
            <div class="row" style="margin-right: 0!important;">
              <div class="col-6" style=" padding-right: 0px;">
                  <ng-template [ngIf]="item.caracteristique.unite !== 'SELECT'">
                      <mat-form-field class="col-12 hadeenInput">
                          <mat-label> Unite </mat-label>
                          <input matInput type="text" [formControlName]="'caracteristique' + item.cat_car_id + 'unite'" readonly>
                      </mat-form-field>
                  </ng-template>
                  <ng-template [ngIf]="item.caracteristique.unite === 'SELECT'">
                      <mat-form-field class="col-12 hadeenInput" *ngIf="!item.caracteristique.select_multiple">
                          <mat-label> Unite </mat-label>
                          <mat-select [formControlName]="'caracteristique' + item.cat_car_id + 'unite'" [required]="item.is_required">
                              <mat-option *ngFor="let value of item.unites" [value]="value.id"> {{ value.unite }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </ng-template>
              </div>
              <div class="col-6" style=" padding-left: 0px;padding-right: 0;">
                <mat-form-field class="col-12 hadeenInput">
                  <mat-label>{{item.caracteristique.nom}}</mat-label>
                  <input matInput type="number" [formControlName]="'caracteristique' + item.cat_car_id" [placeholder]="item.caracteristique.nom" value="0" (change)="affiche()" [required]="item.is_required">
                </mat-form-field>
              </div>
            </div>
          </ng-template>
        </ng-template>

        <ng-template [ngSwitchCase]="'DATE'">
          <mat-form-field class="col-12 hadeenInput">
            <mat-label>{{item.caracteristique.nom}}</mat-label>
            <input matInput [matDatepicker]="picker2" [formControlName]="'caracteristique' + item.cat_car_id" (change)="affiche()" [required]="item.is_required">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
          </mat-form-field>
        </ng-template>

        <ng-template [ngSwitchCase]="'FILE'">
          <mat-form-field class="col-12 hadeenInput">
            <mat-label>{{item.caracteristique.nom}}</mat-label>
            <input style="display: none" accept=".pdf" #fileInput2 type="file" (change)="addFile($event, item.caracteristique)">
            <input matInput [placeholder]="item.caracteristique.nom" [formControlName]="'caracteristique' + item.cat_car_id" [required]="item.is_required">
            <button mat-stroked-button>
              <span *ngIf="!item.caracteristique.isFile">Importer fichier</span>
              <span *ngIf="item.caracteristique.isFile">Validate</span>
            </button>
          </mat-form-field>
        </ng-template>

        <ng-template [ngSwitchCase]="'STRING'">
          <mat-form-field class="col-12 hadeenInput">
            <mat-label>{{item.caracteristique.nom}}</mat-label>
            <input matInput [placeholder]="item.caracteristique.nom" [formControlName]="'caracteristique' + item.cat_car_id" [required]="item.is_required">
          </mat-form-field>
        </ng-template>
      </div>
    </ng-template>
    <!--PICTOS-->
    <div class="col-5" [ngClass]="{'marq': isMarqVisible}">
      <mat-form-field class="w-100" [ngClass]="{'invalid-picto':erroPicto}">
        <mat-label>Marqueurs de confiance </mat-label>
        <mat-select [(value)]="selectedPicto" formControlName="pictoIcon" (selectionChange)="onSelectionChange($event)">
          <mat-select-trigger>
            <div class="row">
              <div class="col-10">
                <img [src]="selectedPicto?.url" alt="picto" width="25" height="25">
              </div>
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let value of pictos;let i = index" [value]="value" style="width: 33%;" [ngClass]="{'float-left': i % 3 !== 2}">
            <div class="text-center">
              <img [src]="value.url" alt="pictos" style="width: 48px;height: 40px;">
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-5">
      <mat-form-field class="w-100" [ngClass]="{'invalid-picto':erroPicto}">
        <mat-label>Votre marqueur</mat-label>
        <input matInput placeholder="Votre marqueur" formControlName="pictoName">
      </mat-form-field>
      <span class="text-danger" style="font-size: 12px;position: relative;top: -19px;" *ngIf="this.getValueForm('pictoName').length>30">*maximum 30 caractères.</span>
    </div>
    <div class="col-2">
      <button class="btn btn-sm btn-dark mt-2 rounded-0" (click)="addPicto()"><i class="fa fa-plus"></i></button>
    </div>
    <div class="col-12" style="line-height: 17px;margin-top: -10px;">
      <span style="font-size: 12px;">* Sélectionnez un picto et écrivez une qualité de votre article par exemple : “convient aux femmes enceintes” ou encore “nourrit intensément la peau”. </span>
    </div>
    <div class="col-12 picto">
      <span class="text-danger" style="font-size: 12px;" *ngIf="erroPicto">Merci de sélectionner au minimum deux impacts.</span>
      <div class="mb-2" *ngIf="pictoList.length">
        <ul class="mb-0">
          <li (click)="removePicto(item.picto_id)" *ngFor="let item of pictoList">
            <img [src]="item.url" width="30" height="30" alt="alt">
            {{item.picto_text}} <span style="color:#999">x</span>
          </li>
        </ul>
      </div>
    </div>

    <ng-template ngFor let-itemcolor [ngForOf]="listCouleurDispo">
      <div class="col-12">
        <div class=" col-12 listCouleur">
          <button mat-mini-fab color="default" *ngIf="itemcolor.isAdd" (click)="removeCouleur(itemcolor)" aria-label="delete icon">
            <i class=" fa fa-times"></i>
          </button>
          <mat-form-field class="col-12 hadeenInput" *ngIf="produit.categorie.has_color">
            <mat-label>Couleur</mat-label>
            <!-- <mat-select [formControlName]="'couleur' + itemcolor.id" [required]="produit.categorie.has_color" (change)="affiche()" [disabled]="initialColors.includes(itemcolor.id)"> -->
            <mat-select [formControlName]="'couleur' + itemcolor.id" [required]="produit.categorie.has_color" (change)="affiche()">
              <mat-select-trigger>
                <div class="row">
                  <div class="col-1" *ngIf="getColor('couleur' + itemcolor.id).codeHexa !== 'MULTI'" [ngStyle]="{'background-color': getColor('couleur' + itemcolor.id).codeHexa}"></div>
                  <div class="col-1 multiColor" *ngIf="getColor('couleur' + itemcolor.id).codeHexa === 'MULTI'"></div>
                  <div class="col-10">{{ getColor('couleur' + itemcolor.id).nom }}</div>
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let value of listCouleur" [value]="value">
                <div class="row">
                  <div class="col-1" *ngIf="value.codeHexa !== 'MULTI'" [ngStyle]="{'background-color': value.codeHexa}"></div>
                  <div class="col-1 multiColor" *ngIf="value.codeHexa === 'MULTI'"></div>
                  <div class="col-10">{{ value.nom }}</div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-12 images">
            <div class="row">
              <div class="imgUpload col-4 col-md-3 text-center" *ngFor="let img of itemcolor.listImages; let ind=index">
                <div class="card">
                  <button type="button" class="close pull-right" aria-label="Close" (click)="remove(img, itemcolor)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <img width="auto" height="auto" class="" [src]="img.urlImg" width="100%" style="width: 100%; height: 100%; object-fit: cover;">
                </div>
                <input *ngIf="img.id" type="number" class="noArrow text-center m-auto" (input)="orderChange($event, img, itemcolor)" maxlength="2" style="max-width: 40px; background-color: white;" [value]="img.order">
              </div>
            </div>
            <div class="col-12 d-block mt-3 text-right">
              <button class="updateOrder m-auto w-auto" [disabled]="!itemcolor.edited" (click)="orderUpdate(itemcolor)">mettre à jours l'ordre des images</button>
            </div>
            <div class="row">
              <div class="col-12" style="line-height: 17px;">
                <span style="font-size: 12px;">*3 photos minimums obligatoires : l’article sur fond neutre, l’article mise en scene et une photo packaging.</span>
                <span class="text-danger" style="font-size: 12px;" *ngIf="this.imgListError"><br>Vous devez ajouter 3 photos minimum.</span>
              </div>
              <div class="imgUpload col-4 col-md-3">
                <div class="card text-center" [ngClass]="{'ng-invalid': isInvalid('listImage' + itemcolor.id)}">
                  <input id="fileAdd{{itemcolor.id}}" accept="image/png, image/jpeg, image/jpg, image/webp" type="file" style="display: none;" name="image" (change)="fileProgress($event, itemcolor)" />
                  <label for="fileAdd{{itemcolor.id}}" class="text-center m-auto w-100">
                    <mat-icon aria-hidden="false">cloud</mat-icon>
                    <p style="font-size: 9px; margin: -8px 0 3px;">(jpg, png, jpeg, webp)</p>
                    <div style="color: #000;">
                      Ajouter des photos
                    </div>
                  </label>
                </div>
              </div>
              <!-- <div class="col-12">
                <span *ngIf="imgListError" class="text-danger" style="font-size: 12px;">Merci d'ajouter au moins 3 images.</span>
              </div> -->
            </div>
          </div>

          <div class="col-12" *ngIf="!produit.categorie.has_taille">
            <div class="row">
              <div class="col-7">Pièce(s) disponible(s) :</div>
              <mat-form-field class="col-5 hadeenInput">
                <input matInput type="number" [formControlName]="'pieceDisponnible' + itemcolor.id" (change)="affiche()">
              </mat-form-field>
            </div>
          </div>

          <div class="col-12" *ngIf="produit.categorie.has_taille">
            <!--taillesPerItem[itemcolor.id]-->
            <div class="field hadeenInput" [ngClass]="{'ng-invalid': isInvalid('taille' + itemcolor.id)}">
              <ng-select2 id="taille{{itemcolor.id}}" [data]="taillesPerItem[itemcolor.id] ? taillesPerItem[itemcolor.id] : listTailles"
                placeholder="Taille" [options]="options" [formControlName]="'taille' + itemcolor.id" [required]="produit.categorie.has_taille" readonly
                (valueChanged)="setTailleDispo($event, itemcolor)">
              </ng-select2>
              <label style="margin-bottom: 0px;" for="taille">Taille</label>
            </div>
          </div>

          <ng-template [ngIf]="produit.categorie.has_taille">
            <div class="col-12 my-1" *ngFor="let taille of itemcolor.listTailleDispo">
              {{taille.valeur}} Pièce disponible :
              <input type="number" class="disponnible" required [ngClass]="{'ng-invalid': isInvalid(itemcolor.id +'tailleDispo' + taille.id)}" [formControlName]="itemcolor.id +'tailleDispo' + taille.id">
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>

    <mat-form-field class="col-6 hadeenInput">
      <mat-label>Prix promotion (facultatif)</mat-label>
      <input matInput type="number" placeholder="Prix promotion(€)" formControlName="prixPromotion" (change)="affiche()">
      <span matSuffix>€</span>
    </mat-form-field>

    <div class="col-6 text-center" style="padding-top: 6px;">
      <button class="btn btn-sm btn-dark rounded-0" (click)="togglePromo()">Prix promo par lot</button>
    </div>
    <div class="col-12 mb-2" style="margin-top: -13px;">
      <span style="font-size: 12px;">*souhaitez-vous faire bénéficier les acheteurs d’une réduction sur un achat multiples du même produit? Cela pourrait augmenter votre volume d’affaires sur la commande de 50%.</span>
    </div>
    <div class="col-12" *ngIf="asPromo" style="padding: 0 30px;">
      <button mat-mini-fab color="default" aria-label="delete icon" class="removePromo" (click)="togglePromo()">
        <i class=" fa fa-times"></i>
      </button>
      <div class="row cardPromo pt-2">
        <div class="col-12">
          Achat multiples avec prix dégressif. (facultatif)
          <mat-icon class="icon-info" style="color: #999;position: relative;top: 7px;top: 7px;" matSuffix mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="Souhaitez vous faire bénéficier les acheteurs d’une réduction sur un achat multiples du même produit? Cela pourrait augmenter votre volume d’affaire sur la commande de 50%.">info</mat-icon>
        </div>
        <span class="mb-2 w-100" style="font-size: 12px;padding-left: 15px;">Merci de renseigner le prix unitaire pour chaque quantité</span>
        <div class="col-6 col-md-4 pr-2">
          <div style="border: 1px solid #dee2e6;" class="px-2">
            <h5 class="mt-2">1 produit</h5>
            <mat-form-field class="w-100">
              <mat-label>Prix</mat-label>
              <input matInput type="number" placeholder="Prix" formControlName="prixNormal">
              <span matSuffix>€</span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6 col-md-4 px-2">
          <div style="border: 1px solid #dee2e6;" class="px-2">
            <h5 class="mt-2">2 produits</h5>
            <mat-form-field class="w-100">
              <mat-label>Prix</mat-label>
              <input matInput type="number" placeholder="Prix" formControlName="prix2">
              <span matSuffix>€</span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6 col-md-4 mt-2 mt-md-0 ml-2 ml-md-0 pl-2">
          <div style="border: 1px solid #dee2e6;" class="px-2">
            <h5 class="mt-2">3 produits</h5>
            <mat-form-field class="w-100">
              <mat-label>Prix</mat-label>
              <input matInput type="number" placeholder="Prix" formControlName="prix3">
              <span matSuffix>€</span>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <mat-form-field class="col-12 hadeenInput" *ngIf="!asPromo">
        <mat-label>Prix normal</mat-label>
        <input matInput type="number" placeholder="Prix normal(€)" formControlName="prixNormal" (change)="affiche()" required>
        <span matSuffix>€</span>
    </mat-form-field>

    <div class="col-12">
      <!-- <p>En cas de vente du produit, Hadéen-place soustraire la frais du commission </p> -->
      <p>en cas de vente de l'article, une commission de {{ vendeur.vendeur.commission }}% sera déduite du prix final.</p>
    </div>

    <mat-form-field class="col-12 hadeenInput">
      <mat-label>Montant pour vous (-{{ vendeur.vendeur.commission }}% de prix pour la commission)</mat-label>
        <input matInput disabled="true" [value]="calculMontant()">
    </mat-form-field>
    <div class="col-12 text-center">
      <p class="PoppinsMedium" style="text-decoration: underline; cursor: pointer;color: #DDB254;" *ngIf="produit.categorie.has_color" (click)="addCouleur()">Une autre couleur disponible à la vente?</p>
      <button class="signin mb-2" (click)="validerStep()">Valider</button>
    </div>
    <div class="col-12">
      <div *ngFor="let item of listCaracteristique">
        <div class="text-danger" *ngIf="formSubmitted && (formGroupe.get('caracteristique' + item.cat_car_id)?.hasError('required') || formGroupe.get('caracteristique' + item.cat_car_id+ 'unite')?.hasError('required'))">
          Le champ {{item.caracteristique.nom}} est requis.
        </div>
      </div>
      <div class="text-danger" *ngIf="erroPicto">
       Merci de sélectionner au minimum deux impacts.
      </div>
      <div *ngFor="let item of listCouleurDispo">
        <div class="text-danger" *ngIf="formSubmitted && (formGroupe.get('couleur'+item.id)?.hasError('required') || formGroupe.get('pieceDisponnible' + item.id)?.hasError('required'))">
          Champ couleur requis.
        </div>
        <div class="text-danger" *ngIf="formSubmitted && formGroupe.get('taille' + item.id)?.hasError('required')">
          Veuillez sélectionner la taille.
        </div>
        <div *ngFor="let elt of item.listTailleDispo">
          <div class="text-danger" *ngIf="formSubmitted && formGroupe.get(item.id +'tailleDispo' + elt.id).value<=0">
            Champ pièce disponible pour taille {{elt.valeur}} requis
          </div>
        </div>
      </div>
      <div class="text-danger" *ngIf="this.imgListError">
        Vous devez ajouter 3 photos minimum.
      </div>
      <div class="text-danger" *ngIf="formSubmitted && formGroupe.get('prixNormal')?.hasError('required')">
        Champ prix requis
      </div>
    </div>
  </div>
</form>
